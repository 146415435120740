const Links = {
  data: [
    {
      id: "000",
      name: "Home",
      url: "/"
    },
    {
      id: "001",
      name: "Projects",
      url: "/projects"
    }
  ]
};

export default Links;
