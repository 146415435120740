import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import links from "../data/links";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // TODO
    console.log(menuOpen);
  }, [menuOpen]);

  const handleClick = () => {
    setMenuOpen(!menuOpen);

    // // wait 5 seconds, then close menu
    // setTimeout(() => {
    //   setMenuOpen(false);
    // }, "5000");
  };

  return (
    <>
      <div
        position="static"
        style={{
          backgroundColor: {
            mobile: "primary.dark",
            desktop: "common.white",
          },
          height: "100px",
        }}
      >
        <Box
          sx={{
            height: "inherit",
            position: "relative",
            display: {
              mobile: "flex",
              desktop: "grid",
            },
            gridTemplateColumns: {
              desktop: "repeat(2,1fr)",
            },
            textAlign: {
              desktop: "center",
            },
            justifyContent: {
              mobile: "space-evenly",
              desktop: "center",
            },
            alignItems: "center",
            flexFlow: {
              mobile: "row-reverse",
            },
          }}
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            <Typography
              variant="title"
              sx={{
                color: { mobile: "common.white", desktop: "primary.main" },
              }}
            >
              Evan Aspaas
            </Typography>
          </Link>
          <nav>
            <MenuIcon
              sx={{
                display: {
                  desktop: "none",
                  tablet: `${menuOpen ? "none" : "block"}`,
                  mobile: `${menuOpen ? "none" : "block"}`,
                },
                color: "common.white",
              }}
              cursor="pointer"
              fontSize="large"
              onClick={handleClick}
            />
            <MenuOpenIcon
              sx={{
                display: {
                  desktop: "none",
                  tablet: `${menuOpen ? "block" : "none"}`,
                  mobile: `${menuOpen ? "block" : "none"}`,
                },
              }}
              color="common.white"
              fontSize="large"
              cursor="pointer"
              onClick={handleClick}
            />
            <Box
              sx={{
                display: {
                  desktop: "flex",
                  tablet: "none",
                  mobile: "none",
                },
                justifyContent: {
                  desktop: "space-evenly",
                },
              }}
            >
              {links.data.map((link, index) => (
                <Link
                  id="link"
                  style={{
                    textDecoration: "none",
                  }}
                  to={`${link.url}`}
                  key={index}
                >
                  <Typography variant="p" sx={{ color: "primary.dark" }}>
                    {link.name}
                  </Typography>
                </Link>
              ))}
              <a
                href="mailto:aspaasevan@gmail.com"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Typography variant="p" sx={{ color: "primary.main" }}>
                  Contact
                </Typography>
              </a>
            </Box>
          </nav>
        </Box>
      </div>
      <Box
        sx={{
          display: {
            mobile: `${menuOpen ? "block" : "none"}`,
          },
          height: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: "2%",
          }}
        >
          {links.data.map((link, index) => (
            <Link
              style={{
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
              to={`${link.url}`}
              key={index}
            >
              <Typography variant="p" sx={{ color: "common.white" }}>
                {link.name}
              </Typography>
            </Link>
          ))}
          <a
            href="mailto:aspaasevan@gmail.com"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <Typography variant="p" sx={{ color: "common.white" }}>
              Contact
            </Typography>
          </a>
        </Box>
      </Box>
    </>
  );
};

export default Header;
