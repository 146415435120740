import React from "react";
import { ThemeProvider } from "@mui/material";
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  // Link,
} from "react-router-dom";

import Home from "./pages/Home.js";
// import Contact from "./pages/Contact.js";
import Projects from "./pages/Projects.js";
import ErrorElement from "./components/ErrorElement";
// import FunStuff from "./pages/FunStuff.js";

import theme from "./styles/theme.js";

// Application Router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorElement />
  },
  {
    path: "/projects",
    element: <Projects />,
    errorElement: <ErrorElement />
  }
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}/>
    </ThemeProvider>
  );
}

export default App;
