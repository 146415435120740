import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Bounce } from "react-reveal";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Projects = () => {
  const [viewMore1, setViewMore1] = useState(false);
  const [viewMore2, setViewMore2] = useState(false);
  const [viewMore3, setViewMore3] = useState(false);
  const [viewMore4, setViewMore4] = useState(false);
  const viewElement1 = useRef();
  const viewElement2 = useRef();
  const viewElement3 = useRef();
  const viewElement4 = useRef();

  return (
    <>
      <Header />
      <div id="projects">
        <section id="project-container">
          <Typography
            variant="h1"
            sx={{
              color: {
                desktop: "primary.dark",
                tablet: "common.white",
                mobile: "common.white",
              },
              padding: "2%",
              textAlign: "center",
              marginBottom: {
                tablet: "120px",
                mobile: "120px",
              },
            }}
          >
            Projects
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                mobile: "1fr",
                tablet: "repeat(2,1fr)",
              },
              gap: "25px",
              margin: "80px 24px",
            }}
          >
            <Bounce left>
              <Paper
                sx={{
                  backgroundColor: "primary.dark",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <Typography variant="h2" sx={{ textAlign: "center" }}>
                  SaverLife Financial Tracker App
                </Typography>
                <p>
                  SaverLife Financial tracker serves as a full-stack web
                  application built for the non-profit organization known as
                  SaverLife.
                </p>
                <Box>
                  <Typography variant="h4" sx={{ marginTop: "40px" }}>
                    Tech Stack
                  </Typography>
                  <p>
                    React | Redux | AntDesign | Node | Express | AWS Amplify |
                    Okta | Docker | PostgreSQL | Data Science API
                  </p>
                </Box>
                <Typography variant="h4" sx={{ marginTop: "40px" }}>
                  Responsibilities
                </Typography>
                <Typography
                  variant="button"
                  onClick={() => {
                    if (viewElement1.current.style.display === "block") {
                      viewElement1.current.style.display = "none";
                    } else {
                      viewElement1.current.style.display = "block";
                    }
                    setViewMore1(!viewMore1);
                  }}
                  sx={{
                    display: {
                      mobile: "block",
                      desktop: "none",
                    },
                  }}
                >
                  View {viewMore1 ? "Less" : "More"}
                </Typography>
                <Box
                  ref={viewElement1}
                  sx={{
                    display: {
                      mobile: `${viewMore1 ? "block" : "none"}`,
                      desktop: "block",
                    },
                  }}
                >
                  <ul
                    style={{
                      lineHeight: "150%",
                      textAlign: "start",
                      fontFamily: "recursive, monospace",
                    }}
                  >
                    <li>
                      Participated in daily standups with Jira tickets to reach
                      product MVP within a two week sprint
                    </li>
                    <li>
                      Solely responsible for configuring Auth with Okta
                      verification middleware
                    </li>
                    <li>
                      Held paired programming sessions with Frontend Developers
                      to establish a main reducer in Redux
                    </li>
                    <li>Deployed React client using AWS Amplify</li>
                    <li>
                      Participated as a key player in architectual decisions for
                      the applications CRUD routes
                    </li>
                    <li>
                      Github workflow config setup to run linting, tests and
                      upload coverage to code climate
                    </li>
                  </ul>
                </Box>
              </Paper>
              <Paper
                sx={{
                  backgroundColor: "primary.dark",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <Typography variant="h2" sx={{ textAlign: "center" }}>
                  Garden Paradise
                </Typography>
                <p>
                  Garden Paradise is a full-stack e-commerce web application
                  allowing customers to browse and purchase items from an online
                  store.
                </p>
                <Box>
                  <Typography variant="h4" sx={{ marginTop: "40px" }}>
                    Tech Stack
                  </Typography>
                  <p>
                    React | Redux | Material-UI | Java | Spring Boot | Stripe |
                    Vercel | Docker
                  </p>
                </Box>
                <Typography variant="h4" sx={{ marginTop: "40px" }}>
                  Responsibilities
                </Typography>
                <Typography
                  variant="button"
                  onClick={() => {
                    if (viewElement2.current.style.display === "block") {
                      viewElement2.current.style.display = "none";
                    } else {
                      viewElement2.current.style.display = "block";
                    }
                    setViewMore2(!viewMore2);
                  }}
                  sx={{
                    display: {
                      mobile: "block",
                      desktop: "none",
                    },
                  }}
                >
                  View {viewMore2 ? "Less" : "More"}
                </Typography>
                <Box
                  ref={viewElement2}
                  sx={{
                    display: {
                      mobile: `${viewMore2 ? "block" : "none"}`,
                      desktop: "block",
                    },
                  }}
                >
                  <ul
                    style={{
                      lineHeight: "150%",
                      textAlign: "start",
                      fontFamily: "recursive, monospace",
                    }}
                  >
                    <li>
                      Utilized React and Redux with Material UI to craft a
                      frontend client
                    </li>
                    <li>Deployed React app to Vercel</li>
                    <li>Crafted UI wireframes using figma</li>
                    <li>Configured Stripe for payment processing</li>
                    <li>
                      Utilized Docker to containerize a Java Spring Boot REST
                      API
                    </li>
                    <li>
                      Paired programming sessions with senior developers on
                      creating JWTs for client authentication
                    </li>
                    <li>
                      Utilized a PostgreSQL Docker image for data persistence
                    </li>
                  </ul>
                </Box>
              </Paper>
            </Bounce>
            <Bounce right>
              <Paper
                sx={{
                  backgroundColor: "primary.dark",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <Typography variant="h2" sx={{ textAlign: "center" }}>
                  Simple Threads
                </Typography>
                <p>
                  Simple Threads is a full-stack e-commerce web application
                  designed to allow customers to purchase online products.
                </p>
                <Box>
                  <Typography variant="h4" sx={{ marginTop: "40px" }}>
                    Tech Stack
                  </Typography>
                  <p>
                    React | Apollo | Next | Node | Keystone.js | GraphQL |
                    TypeScript | SQlite
                  </p>
                </Box>
                <Typography variant="h4" sx={{ marginTop: "40px" }}>
                  Responsibilities
                </Typography>
                <Typography
                  variant="button"
                  onClick={() => {
                    if (viewElement3.current.style.display === "block") {
                      viewElement3.current.style.display = "none";
                    } else {
                      viewElement3.current.style.display = "block";
                    }
                    setViewMore3(!viewMore3);
                  }}
                  sx={{
                    display: {
                      mobile: "block",
                      desktop: "none",
                    },
                  }}
                >
                  View {viewMore3 ? "Less" : "More"}
                </Typography>
                <Box
                  ref={viewElement3}
                  sx={{
                    display: {
                      mobile: `${viewMore3 ? "block" : "none"}`,
                      desktop: "block",
                    },
                  }}
                >
                  <ul
                    style={{
                      lineHeight: "150%",
                      textAlign: "start",
                      fontFamily: "recursive, monospace",
                    }}
                  >
                    <li>
                      Solely responsible for building a frontend client with
                      Next.js.
                    </li>
                    <li>
                      Solely responsible for building a backend GraphQL server
                      with Node, Keystone.js, GraphQL and TypeScript.
                    </li>
                    <li>
                      Solely responsible for building a database with SQlite.
                    </li>
                    <li>
                      Solely responsible for creating a checkout feature using
                      Stripe.
                    </li>
                  </ul>
                </Box>
              </Paper>
            </Bounce>
            <Bounce bottom>
              <Paper
                sx={{
                  backgroundColor: "primary.dark",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <Typography variant="h2">Carbon Emission Estimates</Typography>
                <p>
                  A React application that connects to the Carbon Interface API
                  and allows cabon emission calculations for Electricity,
                  Vehicle, Shipping and Flights.
                </p>
                <Box>
                  <Typography variant="h4" sx={{ marginTop: "40px" }}>
                    Tech Stack
                  </Typography>
                  <p>React | React Router | Carbon Interface API</p>
                </Box>
                <Typography variant="h4" sx={{ marginTop: "40px" }}>
                  Responsibilities
                </Typography>
                <Typography
                  variant="button"
                  onClick={() => {
                    if (viewElement4.current.style.display === "block") {
                      viewElement4.current.style.display = "none";
                    } else {
                      viewElement4.current.style.display = "block";
                    }
                    setViewMore4(!viewMore4);
                  }}
                  sx={{
                    display: {
                      mobile: "block",
                      desktop: "none",
                    },
                  }}
                >
                  View {viewMore4 ? "Less" : "More"}
                </Typography>
                <Box
                  ref={viewElement4}
                  sx={{
                    display: {
                      mobile: `${viewMore4 ? "block" : "none"}`,
                      desktop: "block",
                    },
                  }}
                >
                  <ul
                    style={{
                      lineHeight: "150%",
                      textAlign: "start",
                      fontFamily: "recursive, monospace",
                    }}
                  >
                    <li>
                      Solely responsible for setup and configuring the React
                      application to the Carbon Interface API.
                    </li>
                    <li>
                      Solely responsible for creating UI design wireframes via
                      Figma.
                    </li>
                  </ul>
                </Box>
              </Paper>
            </Bounce>
          </Box>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Projects;
