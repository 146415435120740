const skillset = {
  frontendSkills: [
    { name: "JavaScript", type: "String" },
    { name: "React", type: "String" },
    { name: "Redux", type: "String" },
    { name: "MobX", type: "String" },
    { name: "Vue.js", type: "String" },
    { name: "HTML5", type: "String" },
    { name: "Headless CMS", type: "String" },
    { name: "Material UI", type: "String" },
    { name: "jQuery", type: "String" },
    { name: "CSS", type: "String" },
    { name: "SCSS", type: "String" },
    { name: "Less", type: "String" }
  ],
  backendSkills: [
    { name: "Docker", type: "String" },
    { name: "Git", type: "String" },
    { name: "GCP", type: "String" },
    { name: "AWS", type: "String" },
    { name: "GraphQL", type: "String" },
    { name: "PHP", type: "String" },
    { name: "Laravel", type: "String" },
    { name: "Node.js", type: "String" },
    { name: "Express", type: "String" },
    { name: "Java", type: "String" },
    { name: "REST APIs", type: "String" },
    { name: "OAuth2.0", type: "String" },
    { name: "PostgreSQL", type: "String" },
    { name: "MySQL", type: "String" },
    { name: "SQLite3", type: "String" },
    { name: "MongoDB", type: "String" },
    { name: "DynamoDB", type: "String" }
  ]
};

export default skillset;
