/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "../styles/components/footer.css";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          mobile: "column-reverse",
        },
        alignItems: "center",
        padding: "2%",
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          color: {
            desktop: "primary.dark",
            tablet: "common.white",
            mobile: "common.white",
          },
          fontSize: {
            tablet: "1rem",
            mobile: "1rem",
          },
        }}
        variant="p"
      >
        © 2023-2024 Made with 🖤 by Evan Aspaas
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3,1fr)",
          alignItems: "center",
          gap: "20px",
          padding: "2%",
          marginBottom: "80px",
        }}
      >
        <a
          href="https://github.com/easpaas"
          title="Evan Aspaas' Github"
          className="fa fa-github"
          target="_blank"
          rel="noreferrer"
        >
          <Typography sx={{ color: "primary.main" }} variant="a" />
        </a>
        <a
          href="https://www.linkedin.com/in/evan-aspaas-a80259198/"
          title="Evan Aspaas' LinkedIn"
          className="fa fa-linkedin"
          target="_blank"
          rel="noreferrer"
        >
          <Typography color="primary.main" variant="a" />
        </a>
        <a
          href="/media/Resume.pdf"
          className="fa fa-file-pdf-o"
          title="Evan Aspaas' Resume"
          target="_blank"
          rel="noreferrer"
        >
          <Typography color="primary.main" variant="a" />
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
