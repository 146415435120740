import React from "react";
import { createRoot } from 'react-dom/client';


/* Stylesheets */
import "./index.css";
import "./styles/pages/index.css";
import "./styles/pages/home.css";
import "./styles/pages/projects.css";

import App from "./App";

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App />);
