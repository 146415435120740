import React, { useState } from "react";

import Fab from "@mui/material/Fab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Bounce } from "react-reveal";
import "../styles/pages/home.css";
import "../styles/main.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import skillset from "../data/skillset";

const Home = () => {
  // setSkills are req. for hooks but not needed in this case
  // eslint-disable-next-line
  const [skills, setSkills] = useState(skillset);
  // eslint-disable-next-line

  return (
    <>
      <Header />
      <div id="home">
        <section id="introduction">
          <Box
            sx={{
              display: {
                mobile: "none",
                desktop: "grid",
              },
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "150px",
                backgroundColor: "primary.main",
                color: "white",
              }}
            >
              Howdy!
            </Typography>
          </Box>
          <Box
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div id="image-container">
              <img
                src="/media/profileShotSilo.png"
                alt="Profile Headshot of Evan Aspaas"
              />
            </div>
            <Fab
              color="primary"
              sx={{ color: "common.white", marginBottom: "80px" }}
              variant="extended"
              href="#about"
            >
              <ExpandMoreIcon />
              <Typography variant="p" sx={{ textTransform: "none" }}>
                About Evan
              </Typography>
            </Fab>
          </Box>
        </section>
        <section id="about">
          <Bounce bottom>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "20px",
                width: "80%",
                margin: "0 auto",
              }}
            >
              <Typography variant="p">
                Before becoming a Software Developer, Evan worked in Customer
                Service for 15 plus years. Five of those years, were focused
                around the intricacies of the speciality coffee industry. His
                journey into the tech industry (to become a Software Developer)
                began back in 2013 after taking a few CS classes at his local
                community college; leaving a thirst for more.
              </Typography>
              <Typography variant="p">
                Evan is currently focused on improving his software development
                skills and capabilities; specifically, with frontend
                technologies. Most of Evan's professional exposure comes from
                utilizing a variety of technologies across the entire stack,
                including, but not limited to, Node, JavaScript, and React.
                However, he has also contributed to production codebases using
                languages such as Java, Typescript and PHP.
              </Typography>
            </Box>
          </Bounce>
        </section>
        <section id="frontend-experience">
          <Typography
            variant="h1"
            sx={{ textAlign: "center", marginBottom: "40px", color: "white" }}
          >
            Frontend Experience
          </Typography>
          <Box
            sx={{
              width: {
                desktop: "50%",
              },
              margin: {
                desktop: "80px auto",
              },
            }}
          >
            <Grid
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  mobile: "repeat(2, 1fr)",
                  tablet: "repeat(4,1fr)",
                },
                gap: "20px",
              }}
            >
              <Bounce left>
                {skills.frontendSkills.map((skill, key) => (
                  <Chip
                    key={key}
                    label={skill.name}
                    sx={{ fontSize: "1rem", width: "100%" }}
                  />
                ))}
              </Bounce>
            </Grid>
          </Box>
        </section>
        <section id="backend-experience">
          <Typography
            variant="h1"
            sx={{ color: "white", textAlign: "center", marginBottom: "40px" }}
          >
            Backend Experience
          </Typography>
          <Box
            sx={{
              width: {
                desktop: "50%",
              },
              margin: {
                desktop: "80px auto",
              },
            }}
          >
            <Grid
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  mobile: "repeat(2, 1fr)",
                  tablet: "repeat(4,1fr)",
                },
                gap: "20px",
              }}
            >
              {skills.backendSkills.map((skill, key) => (
                <Bounce right>
                  <Chip
                    key={key}
                    label={skill.name}
                    sx={{ fontSize: "1rem", width: "100%" }}
                  />
                </Bounce>
              ))}
            </Grid>
          </Box>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
