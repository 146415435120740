import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#b65c31",
      dark: "#9e4e2c",
    },
    secondary: {
      main: "#5e2e15",
      dark: "#401400",
    },
  },
  typography: {
    fontFamily: ["recursive", "monospace"].join(","),
    h1: {
      // Page Title
      fontFamily: "Lobster",
      fontSize: "5rem",
      fontWeight: "500",
    },
    h2: {
      // Section Title
      fontSize: "2rem",
    },
    h3: {
      // Subheader
      fontSize: "1.4rem",
      fontWeight: "500",
    },
    h4: {
      // Text Emphasis
      fontSize: "1.2rem",
      fontWeight: "900",
    },
    title: {
      fontFamily: "Lobster",
      fontSize: "2.2rem",
    },
    p: {
      fontWeight: "400",
      fontSize: "1.2rem",
      fontFamily: ["recursive", "monospace"].join(","),
      lineHeight: "1.8rem",
      mobile: {
        lineHeight: "2rem",
      },
    },
    a: {
      textDecoration: "none",
      cursor: "pointer",
    },
    button: {
      letterSpacing: ".2rem",
      fontWeight: "800",
      cursor: "pointer",
      color: "palette.secondary.dark",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
});

export default theme;
